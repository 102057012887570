.tab-grid-custom {
  display: flex;
  flex-direction: column;
}

.settings-title {
  font-family: OpenSauceSansSemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  letter-spacing: -0.006em;
  color: var(--neutral_900);
  margin-bottom: 24px;
  margin-top: 20px;
  padding-right: 0;
  padding-left: 24px;
}

.tab-list {
  display: flex;
  flex-direction: row;
  padding-left: 1.5rem;
}

/*
.tab-list > button {
    padding-top: 1.5rem;
    border: none;
    width: 200px;
    height: 28px;
    background-color: transparent;
}

button {
    font-family: 'OpenSauceSansRegular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    letter-spacing: -0.006em;
    color: #586173;
} */

.tab-content {
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
}

.content-1 {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  height: 350px;
  width: 544px;
  border: 1px solid var(--neutral_200);
  border-radius: 12px;
}

.content-text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 1rem;
  width: 94%;
  background: var(--neutral_100);
  border: 1px solid var(--neutral_200);
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}

.content-text > span {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: -0.09px;
  color: var(--neutral_900);
}

.content-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px 24px;
  gap: 12px;
  width: 544px;
  height: 248px;
}

.content-2 {
  height: 435px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0;
  width: 544px;
  border: 1px solid var(--neutral_200);
  border-radius: 12px;
}

.custom-btn-class {
  border: none;
  width: 122px;
  height: 24px;
  background-color: transparent;
  font-family: OpenSauceSansLight;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.006em;
  color: var(--neutral_600);
}

.react-tabs__tab-list {
  border-bottom: 1px solid var(--neutral_200) !important;
  margin-bottom: 24px;
  width: 1218px !important;
}

.react-tabs__tab {
  display: inline-block;
  bottom: -1px;
  position: relative;
  list-style: none;
  padding: 6px 12px;
  cursor: pointer;
  margin-left: 24px;
}

.react-tabs__tab-panel {
  margin: 0 1.2rem;
}

.react-tabs__tab--selected {
  border-bottom: 4px solid var(--base_blue) !important;
}
