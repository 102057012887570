.herosection-wrapper {
  display: flex;
  flex-direction: column;
}

.hero-top-container {
  display: flex;
  gap: 128px;
}

.hero-text-cont {
  margin-top: 96px;
  width: 448px;
  height: 216px;
}

.hero-text > h2,
.hero-text > h2 > h3 {
  font-family: OpenSauceSansSemiBold;
  font-size: 56px;
  line-height: 72px;
  letter-spacing: -0.035em;
}

.hero-text > h2 > span {
  font-family: OpenSauceSansBold;
  font-size: 56px;
  line-height: 72px;
  letter-spacing: -0.015em;
  color: var(--base_blue);
}

.hero-text > p {
  margin-top: 24px;
  font-family: Inter;
  font-weight: 400;
  font-size: 18px;
  line-height: 32px;
  color: #818a9c;
}

.hero-btn {
  margin-top: 48px;
  display: flex;
  gap: 16px;
}

.herosection-register-btn {
  width: 125px;
  height: 48px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  border: none;
  color: #fff;
  line-height: 24px;
  letter-spacing: -0.09px;
  justify-content: center;
  background: #1355ff;
}

.read-faq-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--neutral_900);
  text-align: center;
  width: 136px;
  height: 48px;
  background: var(--neutral_0);
  border: 1px solid #e7eaee;
  border-radius: 6px;
}

.read-faq-btn > svg {
  color: var(--neutral_900);
  width: 18px;
  height: 18px;
}

.read-faq-btn > span {
  color: var(--neutral_900);
  font-family: Inter;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  margin-left: 10px;
}

.hero-btn > a:hover > .read-faq-btn > svg,
.hero-btn > a:hover > .read-faq-btn > span {
  color: var(--neutral_0);
}

.hero-image-cont {
  margin-top: 64px;
  position: relative;
}

.hero-img > img {
  object-fit: cover;
  width: 544px;
  height: 464px;
  border-radius: 16px;
}

.hero-image-cont > .card-container {
  position: absolute;
  left: -12%;
  bottom: 7%;
}

.hero-bottom-container {
  margin-top: 88px;
}

.hero-logos {
  display: flex;
  list-style-type: none;
  align-content: center;
  background: #fff;
  border: 1px solid #e7eaee;
  border-radius: 6px;
  text-align: center;
}

.hero-logos > li {
  width: 280px;
  height: 112px;
  cursor: pointer;
}

.hero-logos > li:not(:last-child) {
  border-right: 1px solid #e7eaee;
}

.hero-logos > li > img {
  margin-top: 36px;
}
