.sidebar-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  max-height: 100%;
  background: #fff;
  border-right: 1px solid #e7eaee;
}

.profile-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.usericon {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.profile-name > img {
  width: 32px;
  height: 32px;
  margin-right: 8px;
}

.profile-name > span {
  font-family: OpenSauceSansRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.006em;
  color: #07090d;
  margin-right: 5px;
}

.sidebar-navbar {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: flex-start;
  margin-top: 20px;
}

.menu-item,
.menu-item.active {
  width: 164px;
  height: 41px;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding-left: 12px;
}

/* .menu-item.active {
  width: 95.5%;
}
*/

.menu-item > svg {
  width: 14px;
  height: 17px;
  margin-right: 12px;
  color: #979fb3;
}

.menu-item > span {
  font-family: OpenSauceSansRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.006em;
  color: #979fb3;
}

.menu-item.active > svg {
  color: #fff;
}

.menu-item.active > span {
  color: #fff !important;
  border-right: 5px solid #2457fa;
}

.sidebar-navbar > .menu-item.active {
  background: var(--base_blue);
  border-radius: 12px;
}

.sidebar-navbar-second > .menu-item.active {
  background: var(--base_blue);
  border-radius: 12px;
}

.sidebar-navbar-second {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.collapse {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.collapse > svg {
  color: var(--neutral_900);
  margin-right: 8px;
}

.collapse > span {
  font-family: OpenSauceSansRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: -0.006em;
  color: #979fb3;
}
