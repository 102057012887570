.sidenav-div {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.payment-table {
  width: 80%;
  border: 1px solid var(--neutral_200);
  border-radius: 6px 6px 0 0;
  margin-left: 1.5rem;
}

.payment-table > table th:not(:first-child),
table td:not(:first-child) {
  width: 164px;
}
