.account-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 32px;
  max-width: 1120px;
  margin: 0 auto;
}

.account-detail-left {
  width: 544px;
  display: flex;
  flex-direction: column;
}

.account-detail-right {
  width: 544px;
  display: flex;
  flex-direction: column;
}

.account-hometitle {
  display: flex;
  padding-top: 120px;
  font-size: 14px;
  color: var(--base_blue);
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1.25px;
}

.account-title {
  font-family: OpenSauceSansBold;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 60px;
  letter-spacing: -0.015em;
  color: #061237;
  margin-top: 16px;
  padding-bottom: 24px;
}

.account-title > span {
  color: #1355ff;
}

.account-description {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: #3c4353;
  margin-bottom: 36px;
}

.account-buy-order {
  width: 544px;
  background: #fff;
  border: 1px solid #e7eaee;
  border-radius: 12px;
}

.choose-game {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 32px;
  border-bottom: 1px solid #e7eaee;
}

.choose-game-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #586173;
  width: 454px;
}

.choose-game-edit {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #1355ff;
}

.choose-plan {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 32px;
  border-bottom: 1px solid #e7eaee;
}

.choose-plan-text {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: var(--neutral_800);
}

.order-boost-detail {
  padding: 32px;
  border-bottom: 1px solid #e7eaee;
}

.starting,
.desired {
  display: flex;
  flex-direction: row;
}

.starting .icon,
.desired .icon,
.boost-type-input .icon,
.boost-region .icon,
.queue-type .icon {
  width: 0;
  height: 0;
}

.starting .login-input,
.desired .login-input,
.boost-type-input .login-input,
.boost-region .login-input,
.queue-type .login-input {
  padding: 0;
}

.starting .dropdown-input,
.desired .dropdown-input {
  width: 232px;
  margin-right: 0.5rem;
}

/* dropdown toggle changes */

/* .dropdown-input.active.is-open > .Dropdown-menu {
  display: none !important;
} */

.choose-plan-checkbox {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-bottom: 1.2rem;
}

.starting .input-group,
.desired .input-group {
  width: 213px;
  margin-right: 0.5rem;
}

.order-summary-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 544px;
  background: #fff;
  border: 1px solid #e7eaee;
  border-radius: 12px;
  padding: 40px 0;
}

.order-summary {
  padding-bottom: 2.5rem;
}

.order-summary > h4 {
  font-family: OpenSauceSansBold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #07090d;
}

.order-line {
  width: 464px;
  height: 0;
  border-bottom: 1px solid #e7eaee;
}

.pay-btn-ub {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  width: 464px;
  gap: 8px;
  background: #1355ff;
  border-radius: 6px;
  border: none;
  margin-top: 2rem;
}

.account-guarantee {
  display: flex;
  padding-bottom: 52px;
}

.account-guarantee-1,
.account-guarantee-2 {
  display: flex;
  align-items: center;
}

.account-guarantee-1 > span,
.account-guarantee-2 > span {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #07090d;
  padding: 0 2.5rem 0 1rem;
}

.account-guarantee-1 > img,
.account-guarantee-2 > img {
  width: 15px;
  height: 15px;
}

.account-img-card {
  position: relative;
}

.account-img {
  padding-top: 48px;
  margin-bottom: 40px;
}

.account-img > img {
  width: 544px;
  height: 464px;
  border-radius: 16px;
  object-fit: cover;
}

.account-img-card > .card-container {
  position: absolute;
  bottom: 12%;
  left: 38%;
}

.account-continue-btn > button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.account-continue-btn > button > img {
  padding-left: 12px;
}

.order-cost-from-checkbox > ul {
  list-style-type: none;
}

.selectedCheckboxInOrder > li {
  display: flex;
  justify-content: space-between;
}

.selectedCheckboxInOrder > li > span:first-child {
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: var(--neutral_600);
}

.selectedCheckboxInOrder > li > span:last-child {
  font-family: OpenSauceSansRegular;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: -0.015em;
  color: var(--neutral_900);
}

.stripe-pay-btn {
  margin-bottom: 28px;
}

.stripe-pay-btn > button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.have-question > div {
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
}

.have-question > div > .read-faq {
  color: var(--base_blue);
  margin-left: 8px;
}
