.timeline-wrapper {
  position: relative;
  display: flex;
  gap: 16px;
}

.timeline-heading-wrapper {
  text-align: center;
}

.timeline-second-heading {
  color: var(--neutral_900);
  text-align: center;
  font-family: OpenSauceSansSemiBold;
  font-size: 30px;
  line-height: 60px;
  letter-spacing: -0.015em;
}

.timeline-second-heading > h2 > span {
  color: var(--base_blue);
}

.timeline-list1,
.timeline-list2 {
  width: 544px;
  background: var(--neutral_0);
  display: flex;
  flex-direction: column;
}

.long-timeline-line {
  width: 16px;
  height: 544px;
  margin-top: 120px;
  z-index: 1;
}

/* .timeline-bg-light {
  position: absolute;
  width: 1360px;
  height: 411px;
  background: var(--neutral_100);
  margin-left: -120px;
  border-radius: 20px;
  top: 220%;
  left: 11%;
  right: 0;
} */

.timeline-list1 > .timeline-item:first-child {
  margin-top: 232px;
}

.timeline-list1 > .timeline-item:last-child {
  margin-top: 208px;
  z-index: 1;
}

.timeline-list2 > .timeline-item:first-child {
  margin-top: 56px;
}

.timeline-list2 > .timeline-item:last-child {
  margin-top: 208px;
  z-index: 1;
}

/* 
.timeline-list1 > div#2.timeline-item-content {
    margin-top: 232px;
}

.timeline-list1 > div#4.timeline-item-content {
    margin-top: 208px;
    z-index: 1;
}

.timeline-list2 > div#3.timeline-item-content {
    margin-top: 180px;
    z-index: 1;
} */
