.dashboard-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
}

.dashboard-order-search {
  margin-left: auto;
}

.dash-table {
  border: 1px solid var(--neutral_200);
  border-radius: 6px 6px 0 0;
  margin-left: 1.5rem;
  margin-right: 5px;
}

.search-button-bar {
  display: flex;
  flex-direction: row;
}

.button-bars {
  display: flex;
  flex-direction: row;
  margin-left: 1.5rem;
}

.dashboard-btn {
  padding: 8px 24px;
  gap: 8px;
  background: var(--neutral_0);
  border: 1px solid #e7eaee;
  border-radius: 99px;
  margin-right: 10px;
  font-family: OpenSauceSansRegular;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: #07090d;
}

.dashboard-btn-active {
  background: var(--base_blue);
  border: 1px solid var(--base_blue);
  font-family: OpenSauceSansRegular;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  color: var(--neutral_0);
}
