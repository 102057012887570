.about-container {
  display: flex;
  flex-direction: row;
}

.about-left {
  position: relative;
}

.about-pic > img {
  width: 544px;
  height: 464px;
  border-radius: 16px;
  object-fit: cover;
}

.about-left > .card-container {
  position: absolute;
  bottom: 7%;
  left: 38%;
}

.about-description {
  margin-left: 128px;
  margin-top: 2rem;
}

.about-bold-text {
  font-family: OpenSauceSansSemiBold;
  width: 448px;
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 60px;
  letter-spacing: -0.015em;
  color: #061237;
  padding-bottom: 1.5rem;
}

.about-bold-text > span {
  color: #1355ff;
}

.about-text-description {
  font-family: Inter;
  font-style: normal;
  width: 448px;
  font-weight: 300;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.18px;
  color: #3c4353;
  padding-bottom: 2.5rem;
}

.about-guarantee {
  display: flex;
  padding-bottom: 52px;
}

.about-guarantee-1,
.about-guarantee-2 {
  display: flex;
  align-items: center;
}

.about-guarantee-1 > span,
.about-guarantee-2 > span {
  font-family: Inter;
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #07090d;
  padding: 0 2.5rem 0 1rem;
}

.about-guarantee-1 > img,
.about-guarantee-2 > img {
  width: 15px;
  height: 15px;
}

.learn-more-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 16px 12px 20px;
  gap: 8px;
  background: #1355ff;
  border-radius: 6px;
  width: 148px;
  height: 48px;
  border: none;
  cursor: pointer;
  margin-left: 2px;
}

.learn-more-btn > span {
  color: #fff;
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
}

.learn-more-btn > img {
  width: 15px;
  height: 15px;
}
