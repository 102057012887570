.login-page {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 1120px;
  margin: 0 auto;
}

.login-detail > .home-title {
  text-align: center;
}

.login-div {
  width: 544px;
  height: 540px;
  border: 1px solid #e7eaee;
  border-radius: 12px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.login-detail {
  padding: 40px;
}

.login-text {
  width: 464px;
  height: 40px;
  font-family: OpenSauceSansBold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  letter-spacing: -0.015em;
  color: #07090d;
  padding-bottom: 2.5rem;
}

.login-input {
  border: none;
  width: 100%;
  height: 1.2rem;
  padding: 0.5rem;
}

.login-input:focus {
  outline: none;
}

.forgot-password {
  text-align: right;
  padding-bottom: 28px;
}

.forgot-password > a > span {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #1355ff;
}

.login-btn {
  padding-bottom: 28px;
}

.login-btn-ub {
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  background: #1355ff;
  border-radius: 6px;
  border: none;
  text-align: center;
}

.login-btn > button {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #fff;
  display: flex;
  justify-content: center;
}

.not-a-user {
  display: flex;
  justify-content: center;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #080808;
}

.not-a-user > a {
  font-style: normal;
  font-weight: 500;
  text-align: center;
  letter-spacing: -0.09px;
  color: #1355ff;
  padding-left: 0.5rem;
}

.login-detail > form > div.input:nth-child(2) {
  padding-bottom: 8px;
}

.not-a-user > a::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: var(--base_blue);
  transition: 0.2s;
}

.not-a-user > a:hover::after {
  width: 100%;
}

.not-registered-link > span::after {
  content: '';
  display: block;
  width: 0;
  height: 1px;
  background: var(--base_blue);
  transition: 0.2s;
  margin-left: 73%;
}

.not-registered-link > span:hover::after {
  width: 27%;
}

/*
@media only screen and (max-width: 996px) and (min-width:826px) {

        .login-detail > img {
            width: 77px;
            height: 132px;
        }

        .swal2-popup{
            width: 726px !important;
           height: 440px !important;
        }

        .text > .send {
            font-size: 22px;
        }

        .contact-text {
            font-size: 42px;
            padding-bottom: 14px;
        }
        
        .link-text {
            font-size: 22px;
        }

        .img > img {
            width: 34px;
            height: 34px;
        }

        .img > .bs-insta {
            width: 34px;
            height: 34px;
        }

        .login-error-popup > img {
            width: 115px;
            height: 204px;
        }
        
        .login-err-text > .error-bold-text {
            font-size: 60px;
        }
        
        .login-err-text > .error-text {
            font-size: 18px;
         }
        
}

@media only screen and (max-width: 826px) and (min-width:690px) {

    .login-detail > img {
        width: 70px;
        height: 125px;
    }

    .swal2-popup{
        width: 637px !important;
        height: 386px !important;
    }

    .swal2-image {
        width: 272px;
        height: 100px;
        }

    .text > .send {
        font-size: 20px;

    }

    .contact-text {
        font-size: 32px;
        padding-bottom: 14px;
    }
    
    .link-text {
        font-size: 20px;
    }

    .img > img {
        width: 30px;
        height: 28px;
    }

    .img > .bs-insta {
        width: 30px;
        height: 28px;
    }

    .login-error-popup > img {
        width: 84px;
        height: 155px;
    }
    
    .login-err-text > .error-bold-text {
        font-size: 50px;
    }
    
    .login-err-text > .error-text {
        font-size: 18px;
        line-height: 34px;
     }
}



@media only screen and (max-width: 690px) and (min-width: 515px){

    .login-detail > img {
        width: 68px;
        height: 118px;
        
    }

    .login-register-btn {
        width: 137px;
    }

    form > .extra > p {
        font-size: 13px;
    }
     
    .swal2-popup{
        width: 398px !important;
        height: 347px !important;
    }

    .swal2-image {
        width: 252px;
        height: 96px;
        }

    .text > .send {
        font-size: 15px;
    }

    .contact-text {
        font-size: 24px;
        padding-bottom: 14px;
    }
    
    .link-text {
        font-size: 15px;
    }

    .img > img {
        width: 24px;
        height: 26px;
    }

    .img > .bs-insta {
        width: 24px;
        height: 26px;
    }

    .login-error-popup > img {
        width: 82px;
        height: 152px;
    }
    
    .login-err-text > .error-bold-text {
        font-size: 48px;
    }
    
    .login-err-text > .error-text {
        font-size: 16px;
        line-height: 28px;
     }
}


@media only screen and (max-width:515px) and (min-width:470px){
    
    .login-detail > img {
        width: 64px;
        height: 108px;
    }
       
    .login-register-btn {
        width: 137px;
    }

    form > .extra > p {
        font-size: 12px;
    }

    .swal2-popup{
        width: 398px !important;
        height: 347px !important;
    }

    .swal2-image {
        width: 252px;
        height: 90px;
        }

    .text > .send {
        font-size: 15px;
    }

    .contact-text {
        font-size: 24px;
        padding-bottom: 14px;
    }
    
    .link-text {
        font-size: 15px;
    }

    .img > img {
        width: 24px;
        height: 26px;
    }

    .img > .bs-insta {
        width: 24px;
        height: 26px;
    }


    .login-error-popup > img {
        width: 80px;
        height: 146px;
    }
    
    .login-err-text > .error-bold-text {
        font-size: 42px;
    }
    
    .login-err-text > .error-text {
        font-size: 14px;
        line-height: 24px;
     }
}

@media only screen and (max-width: 470px) {

    .login-detail > img {
        width: 62px;
        height: 100px;
    }

    .login-register-btn {
        width: 133px;
        height: 42px;
    }

    form > .extra > p {
        font-size: 10px;
    }

    .swal2-popup{
        width: 312px !important;
        height: 305px !important;
    }

    .swal2-image {
        width: 197px;
        height: 62px;
    }

    .text > .send {
        font-size: 13px;
    }

    .contact-text {
        font-size: 20px;
        padding-bottom: 14px;
    }
    
    .link-text {
        font-size: 13px;
    }

    .img > img {
        width: 18px;
        height: 24px;
    }

    .img > .bs-insta {
        width: 18px;
        height: 24px;
    }

    .login-error-popup > img {
        width: 74px;
        height: 128px;
    }
    
    .login-err-text > .error-bold-text {
        font-size: 32px;
    }
    
    .login-err-text > .error-text {
        font-size: 12px;
        line-height: 18px;
     }
} */
