.card-container {
  width: 304px;
  height: 144px;

  /* position: absolute;
  bottom: 8%;
  left: 35.5%; */
  background: #fff;
  border: 4px solid #e7eaee;
  border-radius: 10px;
}

.card {
  display: flex;
  flex-direction: column;
  position: absolute;
  align-items: flex-start;
  padding-top: 24px;
  padding-left: 24px;
}

.card-top {
  display: flex;
}

.card-top > img:nth-child(2) {
  width: 13.75px;
  height: 14px;
}

.card-text {
  width: 232px;
  font-family: OpenSauceSansRegular;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: -0.015em;
  color: #07090d;
}

.card-bottom {
  display: flex;
  padding-top: 24px;
}

.card-number {
  font-family: OpenSauceSansSemiBold;
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: -0.025em;
  color: var(--neutral_900);
  margin-left: 24px;
  display: flex;
  align-items: center;
}

.card-bottom > .card-user-icon {
  width: 48px;
  height: 48px;
  background-color: var(--base_blue);
  border-radius: 6px;
  cursor: pointer;
}

.card-user-icon > img:nth-child(1) {
  width: 17px;
  height: 17px;
}

.card-bottom > .card-user-icon > img {
  padding: 16px;
}
