.input {
  width: 464px;
}

.input-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 12px 16px 12px 12px;
  gap: 8px;
  height: 24px;
  background: #fff;
  border: 1px solid #e7eaee;
  border-radius: 6px;
}

.input > .label {
  display: flex;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #07090d;
  margin-bottom: 4px;
}

.input > .label > .password-icon {
  width: 15px;
  height: 15px;
  color: #979fb4;
  margin-top: 2px;
  margin-left: 8px;
}

.input-group > .icon {
  height: 24px;
  width: 24px;
  padding: 0.1rem;
}

.input-group > .icon > svg {
  height: 100%;
  width: 100%;
  color: #acb4c3;
}

.input:nth-child(1) {
  padding-bottom: 1.5rem;
}
