* {
  margin: 0;
  padding: 0;
}

html,
body {
  background: #fff;
}

.root-container {
  max-width: 1120px;
  margin: 0 auto;
}

/* Scrollbar Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6f6f6fe0 #dfe9eb;
}

/* Scrollbar Chrome, Edge and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  border-radius: 5px;
  background-color: #6f6f6fe0 #dfe9eb;
}

*::-webkit-scrollbar-track:hover {
  background-color: #6f6f6fe0 #dfe9eb;
}

*::-webkit-scrollbar-track:active {
  background-color: #6f6f6fe0 #dfe9eb;
}

*::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: #6f6f6fe0 #dfe9eb;
}

*::-webkit-scrollbar-thumb:hover {
  background-color: #6f6f6fe0 #dfe9eb;
}

*::-webkit-scrollbar-thumb:active {
  background-color: #6f6f6fe0 #dfe9eb;
}
