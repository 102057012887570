.reset-div {
  width: 544px;
  height: 405px;
  border: 1px solid #e7eaee;
  border-radius: 12px;
  margin-top: 72px;
  margin-bottom: 450px;
}

.reset-btn {
  padding-bottom: 28px;
}

.reset-btn-ub {
  align-items: center;
  padding: 12px 24px;
  width: 100%;
  background: #1355ff;
  border-radius: 6px;
  border: none;
  text-align: center;
}

.reset-btn > button {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.09px;
  color: #fff;
}
