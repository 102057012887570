:root {
  --neutral_0: #fff;
  --neutral_100: #f6f7f9;
  --neutral_200: #e7eaee;
  --neutral_300: #c3c9d4;
  --neutral_400: #979fb3;
  --neutral_500: #818a9b;
  --neutral_600: #586174;
  --neutral_700: #3c4352;
  --neutral_800: #1b212c;
  --neutral_900: #07090d;
  --lighter_blue: #f0f5ff;
  --base_blue: #2457fa;
  --gray: #071236;
}
